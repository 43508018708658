import { useState } from "react";
import HeaderComponent from "../../components/header";
import BannerComponent from "../../components/banner";
import FeaturesComponent from "../../components/features";
import FooterComponent from "../../components/footer";

function Home() {
  const [openMenu, setOpenMenu] = useState<boolean>(false);

  return (
    <>
      <canvas
        id="matrix-rain"
        className="fixed top-0 left-0 w-full h-full pointer-events-none opacity-50"
        width="1680"
        height="502"
      />
      <div className="blob" />
      <div className="fixed inset-0 pointer-events-none z-[1] grid-overlay" />

      <HeaderComponent openMenu={openMenu} setOpenMenu={setOpenMenu} />
      <BannerComponent />
      <FeaturesComponent />
      <FooterComponent />
    </>
  );
}

export default Home;
