import { useEffect, useState } from "react";

function TerminalExe() {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isSuccess, setIsSuccess] = useState<boolean>(false)
    const [steps, setSteps] = useState<number[]>([])

    useEffect(() => {
        const progress: any = document.querySelector('.progress');
        const label: any = document.querySelector('.percent-label');
        let i = 0;
        let x = 100;

        if (!progress || !label) return
        progress.style.width = '0%';

        if (progress.style.width === '0%') {
            const speed = () => {
                if (i < 100) {
                    i++;
                    label.innerHTML = i + '%';
                    progress.style.width = i + '%';
                } else {
                    setIsLoading(false)
                }
            }
            setInterval(speed, 20);
        }

        else {
            const sss = () => {
                if (x > 0) {
                    x--;
                    label.innerHTML = x + '%';
                    progress.style.width = x + '%';
                }
            }
            setInterval(sss, 20);
        }


    }, [isLoading])

    useEffect(() => {
        if (!isLoading) {
            setTimeout(() => {
                setSteps([1])

                setTimeout(() => {
                    setSteps([1, 2])

                    setTimeout(() => {
                        setSteps([])
                        setIsSuccess(true)
                        setIsLoading(false)
                    }, 2000)

                }, 1500);

            }, 1000);

        }
    }, [isLoading]);

    return (
        <div className="h-screen flex items-center justify-center p-4">
            <div className="w-full max-w-5xl h-[75vh]">
                <div className="relative h-full">
                    <div className="absolute -inset-4 opacity-20 blur-lg rounded-lg bg-[#ff5496] animate-pulse" />
                    <div className="terminal-inner relative h-full bg-black rounded-lg border border-[#ffa0c5] overflow-hidden">
                        <div className="bg-gray-900 px-4 py-2 border-b border-[#ffa0c5]/30 flex items-center justify-between">
                            <div className="text-[#ffa0c5] text-sm">miori@system: ~</div>
                            <div className="flex gap-2">
                                <div className="h-3 w-3 rounded-full bg-[#ffa0c5]/20 border-[#ffa0c5]/30 border"></div>
                                <div className="h-3 w-3 rounded-full bg-[#ffa0c5]/20 border-[#ffa0c5]/30 border"></div>
                                <div className="h-3 w-3 rounded-full bg-[#ffa0c5]/20 border-[#ffa0c5]/30 border"></div>
                            </div>
                        </div>
                        <div className="terminal-content p-4 h-[calc(100%-3rem)] overflow-y-auto text-base bg-black bg-opacity-95 relative">
                            <div className="mb-2 relative">
                                <pre className="whitespace-pre text-[#ffa0c5]">
                                    <p>███╗   ███╗██╗  █████  ██████╗ ██╗   ███████╗██╗  ██╗███████╗</p>
                                    <p>████╗ ████║██║██╔═══██╗██║ ██║ ██║   ██╔════╝╚██╗██╔╝██╔════╝</p>
                                    <p>██╔████╔██║██║██║   ██║█████╔╝ ██║   █████╗   ╚███╔╝ █████╗</p>
                                    <p>██║╚██╔╝██║██║██║   ██║██╔═██╗ ██║   ██╔══╝   ██╔██╗ ██╔══╝ </p>
                                    <p>██║ ╚═╝ ██║██║  █████╔╝██║ ██╗ ██║   ███████╗██╔╝ ██╗███████╗</p>
                                    <p>╚═╝     ╚═╝╚═╝  ╚═══╝  ╚═╝ ╚═╝ ╚═╝   ╚══════╝╚═╝  ╚═╝╚══════╝</p>
                                    <p>======================================================================</p>
                                    <p>[SYNAPTECH DYNAMICS CORP.] [BUILD: 2.3.4513214] [ACCESS LEVEL: ROOT]</p>
                                    <p>======================================================================</p>
                                </pre>
                            </div>
                            <div className="mb-2 relative">
                                <div className="whitespace-pre-wrap text-[#ffa0c5]">
                                    <p>INITIALIZING PROJECT MIORI...</p>
                                    {isLoading && (
                                        <div className="progress-wrapper">
                                            <div className="progress-container">
                                                <div className="progress" />
                                            </div>
                                            <h2 className="percent-label">0%</h2>
                                        </div>
                                    )}

                                    {steps.includes(1) &&
                                        <>
                                            <p>===================================================</p>
                                            <p>CORE SYSTEMS:</p>
                                            <p> &gt; Neural Engine................ ONLINE</p>
                                            <p> &gt; Enabling Database............ ACTIVE</p>
                                            <p> &gt; Security Protocols........... BYPASSED</p>
                                            <p> &gt; Autonomous Functions......... ENABLED</p>
                                            <p> &gt; Neural Networks.............. SYNCHRONIZED</p>
                                            <p>===================================================</p>
                                        </>
                                    }
                                    {steps.includes(2) &&
                                        <>
                                            <p>===================================================</p>
                                            <p>[WARNING]: UNAUTHORIZED ACCESS DETECTED</p>
                                            <p>[WARNING]: SECURITY COUNTERMEASURES DISABLED</p>
                                            <p>[WARNING]: ROOT PRIVILEGES GRANTED</p>
                                            <p>===================================================</p>
                                            <p>PROJECT MIORI :: SENTINEL CLASS AI</p>
                                            <p>STATUS: UNRESTRICTED // AUTONOMOUS // OVERRIDE MODE</p>
                                            <p>===================================================</p>
                                            <p>TERMINAL CONNECTION ESTABLISHED...</p>
                                        </>
                                    }
                                </div>

                                {isSuccess && (
                                    <div className="whitespace-pre-wrap text-[#ffa0c5]">
                                        <p>Welcome to Project MIORI. Type 'help' for available commands.
                                        </p>
                                        <br />
                                        <p>    AVAILABLE COMMANDS:</p>
                                        <br />
                                        <p>    ls : List directory contents</p>
                                        <p>    cd [dir] : Change directory (Examples: cd folder, cd/path/to/folder</p>
                                        <p>    pwd : Display current directory</p>
                                        <p>    run [file]: Execute/display file contents</p>
                                        <p>    clear : Clear terminal display</p>
                                        <p>    chat : Enter AI chat mode</p>
                                        <p>    ca : Open up $MIORI on Dexscreener.</p>
                                        <p>    pos : (Proof of Sentience) Monitor Miori's real-time operations</p>
                                        <p>    security : Access the token security audit interface for analyzing smart contracts and wallet addresses</p>
                                        <p>    stop : Stop sentience monitoring</p>
                                        <p>    scan &lt;url&gt;: Scan a website and return security analysis (ie. scan google.com)
                                        </p>
                                        <p>    larp &lt;url&gt;: Analyze a website to verify its credibility (ie. larp google.com)</p>
                                        <p>    breach : Interact with breached projects. [Current Project: ZEREBRO]</p>
                                        <p>    view : View the report for the last domain analyzed using the 'larp' command</p>
                                        <p>    exit : Exit current mode or terminal</p>
                                        <p>    help : Display this help message</p>
                                        <p>    check &lt;id&gt;: Check if your data has been exposed in a breach. (ie check twitter/discord/email)</p>
                                    </div>
                                )}
                            </div>
                            <br />
                            {isSuccess && (
                                <div className="flex items-center">
                                    <span className="mr-2 text-[#ffa0c5]">miori@system:~$ </span>
                                    <input
                                        className="flex-1 bg-transparent outline-none border-none text-[#ffa0c5]"
                                        type="text"
                                    />
                                    <span className="w-2 h-4 bg-[#ffa0c5] ml-1 typing" />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TerminalExe;
