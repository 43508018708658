import "aos/dist/aos.css";
import "animate.css";
import "./App.css";

import Home from "./features/Home";
import Terminal from "./features/Terminal";
import { Route, Routes } from "react-router-dom";
import MainLayout from "./components/layout";

function App() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <MainLayout className="min-h-screen bg-[#111111] overflow-hidden">
            <Home />
          </MainLayout>
        }
      />
      <Route
        path="/terminal"
        element={
          <MainLayout>
            <Terminal />
          </MainLayout>
        }
      />
    </Routes>
  );
}

export default App;
