export default function FeaturesComponent() {
  return (
    <section id="features" className="relative py-24 px-4">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-3xl font-bold mb-12 text-center relative">
          <span className="text-[#ffa0c5] font-mono uppercase tracking-widest glitch-text relative z-10">
            Ecosystem Features
          </span>
          <div className="absolute inset-0 bg-[#ffa0c5]/5 blur-lg" />
          <div className="absolute h-px w-48 bg-gradient-to-r from-transparent via-[#ffa0c5]/50 to-transparent left-1/2 -translate-x-1/2 bottom-0" />
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="group relative p-8 border border-[#ffa0c5]/20 bg-black/40 backdrop-blur-lg transition-all duration-500 hover:border-[#ffa0c5] overflow-hidden">
            <div className="absolute inset-0 bg-gradient-to-br from-[#ffa0c5]/5 via-transparent to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
            <div className="absolute -inset-1 bg-gradient-to-r from-[#ffa0c5]/0 via-[#ffa0c5]/10 to-[#ffa0c5]/0 opacity-0 group-hover:opacity-100 blur-xl transition-opacity duration-700" />
            <div className="absolute top-0 left-0 w-8 h-8 border-l-2 border-t-2 border-[#ffa0c5]/30 group-hover:border-[#ffa0c5] transition-colors duration-300" />
            <div className="absolute bottom-0 right-0 w-8 h-8 border-r-2 border-b-2 border-[#ffa0c5]/30 group-hover:border-[#ffa0c5] transition-colors duration-300" />
            <div className="relative z-10">
              <div className="relative mb-6">
                <div className="text-[#ffa0c5] transform group-hover:scale-110 transition-transform duration-300 relative z-10">
                  <div className="w-12 h-12 flex items-center justify-center border border-[#ffa0c5]/30 group-hover:border-[#ffa0c5] bg-black/50 transition-all duration-300">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="lucide lucide-terminal w-8 h-8"
                    >
                      <polyline points="4 17 10 11 4 5"></polyline>
                      <line x1="12" x2="20" y1="19" y2="19"></line>
                    </svg>
                  </div>
                </div>
                <div className="absolute -inset-2 bg-[#ffa0c5]/5 blur-md opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
              </div>
              <div className="space-y-4">
                <h3 className="text-[#ffa0c5] text-2xl font-orbitron font-bold mb-3 relative tracking-wider">
                  Terminal Interface
                  <div className="absolute -bottom-1 left-0 w-12 h-0.5 bg-[#ffa0c5]/30 group-hover:w-full transition-all duration-500"></div>
                </h3>
                <p className="text-gray-300 leading-relaxed font-light tracking-wide backdrop-blur-sm relative z-10">
                  <span className="text-[#ffa0c5]/90">
                    Access powerful security{" "}
                  </span>
                  tools through our cyberpunk-inspired terminal. Execute
                  commands, chat with MIORI, and analyze web data with the help
                  of machine learning.
                </p>
                <div className="pt-4 space-y-2 text-sm text-gray-400">
                  <div className="flex items-start space-x-2 group">
                    <span className="text-[#ffa0c5] mt-1 font-mono transform group-hover:scale-110 transition-transform duration-300">
                      ›
                    </span>
                    <span className="font-light tracking-wide group-hover:text-[#ffa0c5]/80 transition-colors duration-300">
                      Chat with the MIORI Agent
                    </span>
                  </div>
                  <div className="flex items-start space-x-2 group">
                    <span className="text-[#ffa0c5] mt-1 font-mono transform group-hover:scale-110 transition-transform duration-300">
                      ›
                    </span>
                    <span className="font-light tracking-wide group-hover:text-[#ffa0c5]/80 transition-colors duration-300">
                      Scan websites for quick security overviews
                    </span>
                  </div>
                  <div className="flex items-start space-x-2 group">
                    <span className="text-[#ffa0c5] mt-1 font-mono transform group-hover:scale-110 transition-transform duration-300">
                      ›
                    </span>
                    <span className="font-light tracking-wide group-hover:text-[#ffa0c5]/80 transition-colors duration-300">
                      Analzye websites for credibility scores
                    </span>
                  </div>
                  <div className="flex items-start space-x-2 group">
                    <span className="text-[#ffa0c5] mt-1 font-mono transform group-hover:scale-110 transition-transform duration-300">
                      ›
                    </span>
                    <span className="font-light tracking-wide group-hover:text-[#ffa0c5]/80 transition-colors duration-300">
                      Access additional tooling in the Miori ecosystem
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="absolute inset-0 border border-[#ffa0c5]/0 group-hover:border-[#ffa0c5]/50 transition-colors duration-500" />
          </div>

          <div className="group relative p-8 border border-[#ffa0c5]/20 bg-black/40 backdrop-blur-lg transition-all duration-500 hover:border-[#ffa0c5] overflow-hidden">
            <div className="absolute inset-0 bg-gradient-to-br from-[#ffa0c5]/5 via-transparent to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-500"></div>
            <div className="absolute -inset-1 bg-gradient-to-r from-[#ffa0c5]/0 via-[#ffa0c5]/10 to-[#ffa0c5]/0 opacity-0 group-hover:opacity-100 blur-xl transition-opacity duration-700"></div>
            <div className="absolute top-0 left-0 w-8 h-8 border-l-2 border-t-2 border-[#ffa0c5]/30 group-hover:border-[#ffa0c5] transition-colors duration-300"></div>
            <div className="absolute bottom-0 right-0 w-8 h-8 border-r-2 border-b-2 border-[#ffa0c5]/30 group-hover:border-[#ffa0c5] transition-colors duration-300"></div>
            <div className="relative z-10">
              <div className="relative mb-6">
                <div className="text-[#ffa0c5] transform group-hover:scale-110 transition-transform duration-300 relative z-10">
                  <div className="w-12 h-12 flex items-center justify-center border border-[#ffa0c5]/30 group-hover:border-[#ffa0c5] bg-black/50 transition-all duration-300">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="lucide lucide-shield w-8 h-8"
                    >
                      <path d="M20 13c0 5-3.5 7.5-7.66 8.95a1 1 0 0 1-.67-.01C7.5 20.5 4 18 4 13V6a1 1 0 0 1 1-1c2 0 4.5-1.2 6.24-2.72a1.17 1.17 0 0 1 1.52 0C14.51 3.81 17 5 19 5a1 1 0 0 1 1 1z" />
                    </svg>
                  </div>
                </div>
                <div className="absolute -inset-2 bg-[#ffa0c5]/5 blur-md opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
              </div>
              <div className="space-y-4">
                <h3 className="text-[#ffa0c5] text-2xl font-orbitron font-bold mb-3 relative tracking-wider">
                  Lens Analysis Engine
                  <div className="absolute -bottom-1 left-0 w-12 h-0.5 bg-[#ffa0c5]/30 group-hover:w-full transition-all duration-500"></div>
                </h3>
                <p className="text-gray-300 leading-relaxed font-light tracking-wide backdrop-blur-sm relative z-10">
                  <span className="text-[#ffa0c5]/90">
                    Real-time security scanner{" "}
                  </span>
                  that flags potential risks and analyzes token behavior
                  patterns before you trade. Your first line of defense in the
                  Solana ecosystem.
                </p>
                <div className="pt-4 space-y-2 text-sm text-gray-400">
                  <div className="flex items-start space-x-2 group">
                    <span className="text-[#ffa0c5] mt-1 font-mono transform group-hover:scale-110 transition-transform duration-300">
                      ›
                    </span>
                    <span className="font-light tracking-wide group-hover:text-[#ffa0c5]/80 transition-colors duration-300">
                      AI-powered threat detection algorithms
                    </span>
                  </div>
                  <div className="flex items-start space-x-2 group">
                    <span className="text-[#ffa0c5] mt-1 font-mono transform group-hover:scale-110 transition-transform duration-300">
                      ›
                    </span>
                    <span className="font-light tracking-wide group-hover:text-[#ffa0c5]/80 transition-colors duration-300">
                      Real-time token vulnerability scanning
                    </span>
                  </div>
                  <div className="flex items-start space-x-2 group">
                    <span className="text-[#ffa0c5] mt-1 font-mono transform group-hover:scale-110 transition-transform duration-300">
                      ›
                    </span>
                    <span className="font-light tracking-wide group-hover:text-[#ffa0c5]/80 transition-colors duration-300">
                      Behavioral analysis of suspicious addresses
                    </span>
                  </div>
                  <div className="flex items-start space-x-2 group">
                    <span className="text-[#ffa0c5] mt-1 font-mono transform group-hover:scale-110 transition-transform duration-300">
                      ›
                    </span>
                    <span className="font-light tracking-wide group-hover:text-[#ffa0c5]/80 transition-colors duration-300">
                      Automated risk assessment reporting
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="absolute inset-0 border border-[#ffa0c5]/0 group-hover:border-[#ffa0c5]/50 transition-colors duration-500"></div>
          </div>

          <div className="group relative p-8 border border-[#ffa0c5]/20 bg-black/40 backdrop-blur-lg transition-all duration-500 hover:border-[#ffa0c5] overflow-hidden">
            <div className="absolute inset-0 bg-gradient-to-br from-[#ffa0c5]/5 via-transparent to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-500"></div>
            <div className="absolute -inset-1 bg-gradient-to-r from-[#ffa0c5]/0 via-[#ffa0c5]/10 to-[#ffa0c5]/0 opacity-0 group-hover:opacity-100 blur-xl transition-opacity duration-700"></div>
            <div className="absolute top-0 left-0 w-8 h-8 border-l-2 border-t-2 border-[#ffa0c5]/30 group-hover:border-[#ffa0c5] transition-colors duration-300"></div>
            <div className="absolute bottom-0 right-0 w-8 h-8 border-r-2 border-b-2 border-[#ffa0c5]/30 group-hover:border-[#ffa0c5] transition-colors duration-300"></div>
            <div className="relative z-10">
              <div className="relative mb-6">
                <div className="text-[#ffa0c5] transform group-hover:scale-110 transition-transform duration-300 relative z-10">
                  <div className="w-12 h-12 flex items-center justify-center border border-[#ffa0c5]/30 group-hover:border-[#ffa0c5] bg-black/50 transition-all duration-300">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="lucide lucide-scan w-8 h-8"
                    >
                      <path d="M3 7V5a2 2 0 0 1 2-2h2" />
                      <path d="M17 3h2a2 2 0 0 1 2 2v2" />
                      <path d="M21 17v2a2 2 0 0 1-2 2h-2" />
                      <path d="M7 21H5a2 2 0 0 1-2-2v-2" />
                    </svg>
                  </div>
                </div>
                <div className="absolute -inset-2 bg-[#ffa0c5]/5 blur-md opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
              </div>
              <div className="space-y-4">
                <h3 className="text-[#ffa0c5] text-2xl font-orbitron font-bold mb-3 relative tracking-wider">
                  Enterprise Scanning Suite
                  <div className="absolute -bottom-1 left-0 w-12 h-0.5 bg-[#ffa0c5]/30 group-hover:w-full transition-all duration-500"></div>
                </h3>
                <p className="text-gray-300 leading-relaxed font-light tracking-wide backdrop-blur-sm relative z-10">
                  <span className="text-[#ffa0c5]/90">
                    Leverage machine learning{" "}
                  </span>
                  algorithms for in-depth domain audits and security
                  assessments. Get detailed reports and actionable insights.
                </p>
                <div className="pt-4 space-y-2 text-sm text-gray-400">
                  <div className="flex items-start space-x-2 group">
                    <span className="text-[#ffa0c5] mt-1 font-mono transform group-hover:scale-110 transition-transform duration-300">
                      ›
                    </span>
                    <span className="font-light tracking-wide group-hover:text-[#ffa0c5]/80 transition-colors duration-300">
                      ML-powered automation for intelligent web auditing
                    </span>
                  </div>
                  <div className="flex items-start space-x-2 group">
                    <span className="text-[#ffa0c5] mt-1 font-mono transform group-hover:scale-110 transition-transform duration-300">
                      ›
                    </span>
                    <span className="font-light tracking-wide group-hover:text-[#ffa0c5]/80 transition-colors duration-300">
                      Dynamic vulnerability assessment with adaptive scanning
                    </span>
                  </div>
                  <div className="flex items-start space-x-2 group">
                    <span className="text-[#ffa0c5] mt-1 font-mono transform group-hover:scale-110 transition-transform duration-300">
                      ›
                    </span>
                    <span className="font-light tracking-wide group-hover:text-[#ffa0c5]/80 transition-colors duration-300">
                      Comprehensive security report generation with remediation
                      steps
                    </span>
                  </div>
                  <div className="flex items-start space-x-2 group">
                    <span className="text-[#ffa0c5] mt-1 font-mono transform group-hover:scale-110 transition-transform duration-300">
                      ›
                    </span>
                    <span className="font-light tracking-wide group-hover:text-[#ffa0c5]/80 transition-colors duration-300">
                      Automated penetration testing with custom attack vectors
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="absolute inset-0 border border-[#ffa0c5]/0 group-hover:border-[#ffa0c5]/50 transition-colors duration-500"></div>
          </div>

          <div className="group relative p-8 border border-[#ffa0c5]/20 bg-black/40 backdrop-blur-lg transition-all duration-500 hover:border-[#ffa0c5] overflow-hidden">
            <div className="absolute inset-0 bg-gradient-to-br from-[#ffa0c5]/5 via-transparent to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-500"></div>
            <div className="absolute -inset-1 bg-gradient-to-r from-[#ffa0c5]/0 via-[#ffa0c5]/10 to-[#ffa0c5]/0 opacity-0 group-hover:opacity-100 blur-xl transition-opacity duration-700"></div>
            <div className="absolute top-0 left-0 w-8 h-8 border-l-2 border-t-2 border-[#ffa0c5]/30 group-hover:border-[#ffa0c5] transition-colors duration-300"></div>
            <div className="absolute bottom-0 right-0 w-8 h-8 border-r-2 border-b-2 border-[#ffa0c5]/30 group-hover:border-[#ffa0c5] transition-colors duration-300"></div>
            <div className="relative z-10">
              <div className="relative mb-6">
                <div className="text-[#ffa0c5] transform group-hover:scale-110 transition-transform duration-300 relative z-10">
                  <div className="w-12 h-12 flex items-center justify-center border border-[#ffa0c5]/30 group-hover:border-[#ffa0c5] bg-black/50 transition-all duration-300">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="lucide lucide-scan w-8 h-8"
                    >
                      <path d="M3 7V5a2 2 0 0 1 2-2h2" />
                      <path d="M17 3h2a2 2 0 0 1 2 2v2" />
                      <path d="M21 17v2a2 2 0 0 1-2 2h-2" />
                      <path d="M7 21H5a2 2 0 0 1-2-2v-2" />
                    </svg>
                  </div>
                </div>
                <div className="absolute -inset-2 bg-[#ffa0c5]/5 blur-md opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
              </div>
              <div className="space-y-4">
                <h3 className="text-[#ffa0c5] text-2xl font-orbitron font-bold mb-3 relative tracking-wider">
                  Enterprise Scanning Suite
                  <div className="absolute -bottom-1 left-0 w-12 h-0.5 bg-[#ffa0c5]/30 group-hover:w-full transition-all duration-500"></div>
                </h3>
                <p className="text-gray-300 leading-relaxed font-light tracking-wide backdrop-blur-sm relative z-10">
                  <span className="text-[#ffa0c5]/90">
                    Leverage machine learning{" "}
                  </span>
                  algorithms for in-depth domain audits and security
                  assessments. Get detailed reports and actionable insights.
                </p>
                <div className="pt-4 space-y-2 text-sm text-gray-400">
                  <div className="flex items-start space-x-2 group">
                    <span className="text-[#ffa0c5] mt-1 font-mono transform group-hover:scale-110 transition-transform duration-300">
                      ›
                    </span>
                    <span className="font-light tracking-wide group-hover:text-[#ffa0c5]/80 transition-colors duration-300">
                      ML-powered automation for intelligent web auditing
                    </span>
                  </div>
                  <div className="flex items-start space-x-2 group">
                    <span className="text-[#ffa0c5] mt-1 font-mono transform group-hover:scale-110 transition-transform duration-300">
                      ›
                    </span>
                    <span className="font-light tracking-wide group-hover:text-[#ffa0c5]/80 transition-colors duration-300">
                      Dynamic vulnerability assessment with adaptive scanning
                    </span>
                  </div>
                  <div className="flex items-start space-x-2 group">
                    <span className="text-[#ffa0c5] mt-1 font-mono transform group-hover:scale-110 transition-transform duration-300">
                      ›
                    </span>
                    <span className="font-light tracking-wide group-hover:text-[#ffa0c5]/80 transition-colors duration-300">
                      Comprehensive security report generation with remediation
                      steps
                    </span>
                  </div>
                  <div className="flex items-start space-x-2 group">
                    <span className="text-[#ffa0c5] mt-1 font-mono transform group-hover:scale-110 transition-transform duration-300">
                      ›
                    </span>
                    <span className="font-light tracking-wide group-hover:text-[#ffa0c5]/80 transition-colors duration-300">
                      Automated penetration testing with custom attack vectors
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="absolute inset-0 border border-[#ffa0c5]/0 group-hover:border-[#ffa0c5]/50 transition-colors duration-500"></div>
          </div>
        </div>
      </div>
    </section>
  );
}
