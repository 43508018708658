import { Dispatch, SetStateAction } from "react";
import { Link } from "react-router-dom";

type IProps = {
  openMenu: boolean;
  setOpenMenu: Dispatch<SetStateAction<boolean>>;
};

export default function HeaderComponent({ openMenu, setOpenMenu }: IProps) {
  return (
    <nav className="fixed top-0 left-0 right-0 z-50 transition-all duration-500 bg-black/80 backdrop-blur-md border-b border-[#ffa0c5]/20">
      <div className="max-w-7xl mx-auto px-3 sm:px-4 py-3 sm:py-4 flex justify-between items-center">
        <div className="text-[#ffa0c5] font-orbitron text-lg sm:text-xl relative group">
          <Link to="/" className="relative z-10 tracking-wider uppercase">
            MIORI
          </Link>
          <div className="absolute inset-0 bg-[#ffa0c5]/10 scale-x-0 group-hover:scale-x-100 transition-transform duration-300"></div>
        </div>
        <div className="flex gap-1 sm:gap-2 md:gap-4">
          <Link
            to="https://x.com/_Miori_xyz"
            className="group flex items-center gap-1 sm:gap-2 px-2 sm:px-4 py-2 border transition-all duration-300 relative overflow-hidden text-sm sm:text-base border-[#ffa0c5] text-[#ffa0c5] hover:bg-[#ffa0c5]/10"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="relative z-10 flex items-center gap-1 sm:gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="lucide lucide-twitter sm:w-4 sm:h-4"
              >
                <path d="M22 4s-.7 2.1-2 3.4c1.6 10-9.4 17.3-18 11.6 2.2.1 4.4-.6 6-2C3 15.5.5 9.6 3 5c2.2 2.6 5.6 4.1 9 4-.9-4.2 4-6.6 7-3.8 1.1 0 3-1.2 3-1.2z" />
              </svg>
              <span className="hidden md:inline">X</span>
            </div>
            <div className="absolute inset-0 bg-gradient-to-r from-[#ffa0c5]/0 via-[#ffa0c5]/20 to-[#ffa0c5]/0 translate-x-[-100%] group-hover:translate-x-[100%] transition-transform duration-500"></div>
          </Link>
          <Link
            to="/"
            className=" group flex items-center gap-1 sm:gap-2 px-2 sm:px-4 py-2 border transition-all duration-300 relative overflow-hidden text-sm sm:text-base border-gray-600 text-gray-600 cursor-not-allowed"
            rel="noopener noreferrer"
          >
            <div className="relative z-10 flex items-center gap-1 sm:gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="lucide lucide-external-link sm:w-4 sm:h-4"
              >
                <path d="M15 3h6v6" />
                <path d="M10 14 21 3" />
                <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6" />
              </svg>
              <span className="hidden md:inline">DEX</span>
            </div>
            <div className="absolute inset-0 bg-gradient-to-r from-[#ffa0c5]/0 via-[#ffa0c5]/20 to-[#ffa0c5]/0 translate-x-[-100%] group-hover:translate-x-[100%] transition-transform duration-500"></div>
          </Link>
          <Link
            to="/"
            className="group flex items-center gap-1 sm:gap-2 px-2 sm:px-4 py-2 border transition-all duration-300 relative overflow-hidden text-sm sm:text-base border-gray-600 text-gray-600 cursor-not-allowed"
          >
            <div className="relative z-10 flex items-center gap-1 sm:gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="lucide lucide-file-code sm:w-4 sm:h-4"
              >
                <path d="M10 12.5 8 15l2 2.5" />
                <path d="m14 12.5 2 2.5-2 2.5" />
                <path d="M14 2v4a2 2 0 0 0 2 2h4" />
                <path d="M15 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7z" />
              </svg>
              <span className="hidden md:inline">Docs</span>
            </div>
            <div className="absolute inset-0 bg-gradient-to-r from-[#ffa0c5]/0 via-[#ffa0c5]/20 to-[#ffa0c5]/0 translate-x-[-100%] group-hover:translate-x-[100%] transition-transform duration-500"></div>
          </Link>
          <Link
            to="/"
            className="group flex items-center gap-1 sm:gap-2 px-2 sm:px-4 py-2 border transition-all duration-300 relative overflow-hidden text-sm sm:text-base border-gray-600 text-gray-600 cursor-not-allowed"
          >
            <div className="relative z-10 flex items-center gap-1 sm:gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="lucide lucide-github sm:w-4 sm:h-4"
              >
                <path d="M15 22v-4a4.8 4.8 0 0 0-1-3.5c3 0 6-2 6-5.5.08-1.25-.27-2.48-1-3.5.28-1.15.28-2.35 0-3.5 0 0-1 0-3 1.5-2.64-.5-5.36-.5-8 0C6 2 5 2 5 2c-.3 1.15-.3 2.35 0 3.5A5.403 5.403 0 0 0 4 9c0 3.5 3 5.5 6 5.5-.39.49-.68 1.05-.85 1.65-.17.6-.22 1.23-.15 1.85v4" />
                <path d="M9 18c-4.51 2-5-2-7-2" />
              </svg>
              <span className="hidden md:inline">Github</span>
            </div>
            <div className="absolute inset-0 bg-gradient-to-r from-[#ffa0c5]/0 via-[#ffa0c5]/20 to-[#ffa0c5]/0 translate-x-[-100%] group-hover:translate-x-[100%] transition-transform duration-500"></div>
          </Link>
          <Link
            to="/"
            className=" group flex items-center gap-1 sm:gap-2 px-2 sm:px-4 py-2 border transition-all duration-300 relative overflow-hidden text-sm sm:text-base border-gray-600 text-gray-600 cursor-not-allowed"
          >
            <div className="relative z-10 flex items-center gap-1 sm:gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="lucide lucide-eye sm:w-4 sm:h-4"
              >
                <path d="M2.062 12.348a1 1 0 0 1 0-.696 10.75 10.75 0 0 1 19.876 0 1 1 0 0 1 0 .696 10.75 10.75 0 0 1-19.876 0" />
                <circle cx="12" cy="12" r="3"></circle>
              </svg>
              <span className="hidden md:inline">Lens</span>
            </div>
            <div className="absolute inset-0 bg-gradient-to-r from-[#ffa0c5]/0 via-[#ffa0c5]/20 to-[#ffa0c5]/0 translate-x-[-100%] group-hover:translate-x-[100%] transition-transform duration-500"></div>
          </Link>
          <Link
            to="/terminal"
            className="group flex items-center gap-1 sm:gap-2 px-2 sm:px-4 py-2 border transition-all duration-300 relative overflow-hidden text-sm sm:text-base border-[#ffa0c5] text-[#ffa0c5] hover:bg-[#ffa0c5]/10"
            rel="noopener noreferrer"
          >
            <div className="relative z-10 flex items-center gap-1 sm:gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="lucide lucide-terminal sm:w-4 sm:h-4"
              >
                <polyline points="4 17 10 11 4 5"></polyline>
                <line x1="12" x2="20" y1="19" y2="19"></line>
              </svg>
              <span className="hidden md:inline">Terminal</span>
            </div>
            <div className="absolute inset-0 bg-gradient-to-r from-[#ffa0c5]/0 via-[#ffa0c5]/20 to-[#ffa0c5]/0 translate-x-[-100%] group-hover:translate-x-[100%] transition-transform duration-500"></div>
          </Link>
        </div>
      </div>
    </nav>
  );
}
