import { useState } from "react";

function Hamburger() {
  const [toggleMenu, setToggleMenu] = useState<boolean>(false);

  return (
    <>
      <div className="fixed top-4 right-4 z-50">
        <button
          className="relative p-2 rounded-lg bg-black border border-[#ffa0c5] hover:border-[#ffb7c5] transition-all duration-300 group overflow-hidden"
          onClick={() => setToggleMenu(!toggleMenu)}
        >
          {!toggleMenu ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="lucide lucide-menu w-6 h-6 #ffa0c5"
            >
              <line x1="4" x2="20" y1="12" y2="12"></line>
              <line x1="4" x2="20" y1="6" y2="6"></line>
              <line x1="4" x2="20" y1="18" y2="18"></line>
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="lucide lucide-x w-6 h-6 #ffa0c5"
            >
              <path d="M18 6 6 18"></path>
              <path d="m6 6 12 12"></path>
            </svg>
          )}
          <div className="absolute inset-0 bg-[rgb(255,160,197,0.2)] transform scale-x-0 group-hover:scale-x-100 transition-transform origin-left"></div>
        </button>

        <div
          className={`absolute right-0 top-14 w-80 bg-black border border-[#ffa0c5] rounded-lg transition-all duration-300 transform ${
            !toggleMenu
              ? "translate-x-full opacity-0"
              : "translate-x-0 opacity-100"
          }`}
        >
          <div className="text-center py-3 border-b border-[#ffa0c5]/30">
            <h2 className="text-[#ffa0c5] font-mono text-lg">SYSTEM ACCESS</h2>
          </div>
          <div className="p-4 space-y-4">
            <button className="w-full text-left relative p-4 border border-[#ffa0c5]/30 rounded-lg  hover:border-[#ffb7c5] transition-all duration-300 group overflow-hidden">
              <div className="absolute inset-0 bg-[#ffa0c5]/5 transform -skew-x-12 translate-x-full  group-hover:translate-x-0 transition-transform duration-700"></div>
              <div className="relative flex items-start space-x-4">
                <div className="text-[#ffa0c5]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="lucide lucide-terminal w-6 h-6"
                  >
                    <polyline points="4 17 10 11 4 5"></polyline>
                    <line x1="12" x2="20" y1="19" y2="19"></line>
                  </svg>
                </div>
                <div className="flex-1">
                  <div className="flex items-center justify-between">
                    <h3 className="text-[#ffa0c5] font-mono text-lg mb-1">
                      BREACH
                    </h3>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="lucide lucide-chevron-right w-5 h-5 text-[#ffa0c5] transform group-hover:translate-x-1  transition-transform"
                    >
                      <path d="m9 18 6-6-6-6"></path>
                    </svg>
                  </div>
                  <p className="text-[#ffa0c5]/70 text-sm font-mono">
                    Access restricted system entry points
                  </p>
                </div>
              </div>
              <div className="absolute inset-0 bg-[#ffa0c5]/20 transform translate-y-full  group-hover:translate-y-0 transition-transform duration-300"></div>
            </button>
            <button className="w-full text-left relative p-4 border border-[#ffa0c5]/30 rounded-lg  hover:border-[#ffb7c5] transition-all duration-300 group overflow-hidden">
              <div className="absolute inset-0 bg-[#ffa0c5]/5 transform -skew-x-12 translate-x-full  group-hover:translate-x-0 transition-transform duration-700"></div>
              <div className="relative flex items-start space-x-4">
                <div className="text-[#ffa0c5]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="lucide lucide-shield w-6 h-6"
                  >
                    <path d="M20 13c0 5-3.5 7.5-7.66 8.95a1 1 0 0 1-.67-.01C7.5 20.5 4 18 4 13V6a1 1 0 0 1 1-1c2 0 4.5-1.2 6.24-2.72a1.17 1.17 0 0 1 1.52 0C14.51 3.81 17 5 19 5a1 1 0 0 1 1 1z"></path>
                  </svg>
                </div>
                <div className="flex-1">
                  <div className="flex items-center justify-between">
                    <h3 className="text-[#ffa0c5] font-mono text-lg mb-1">
                      SECURITY
                    </h3>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="lucide lucide-chevron-right w-5 h-5 text-[#ffa0c5] transform group-hover:translate-x-1  transition-transform"
                    >
                      <path d="m9 18 6-6-6-6"></path>
                    </svg>
                  </div>
                  <p className="text-[#ffa0c5]/70 text-sm font-mono">
                    Monitor and analyze network vulnerabilities and system
                    defenses
                  </p>
                </div>
              </div>
              <div className="absolute inset-0 bg-[#ffa0c5]/20 transform translate-y-full  group-hover:translate-y-0 transition-transform duration-300"></div>
            </button>
            <button className="w-full text-left relative p-4 border border-[#ffa0c5]/30 rounded-lg  hover:border-[#ffb7c5] transition-all duration-300 group overflow-hidden">
              <div className="absolute inset-0 bg-[#ffa0c5]/5 transform -skew-x-12 translate-x-full  group-hover:translate-x-0 transition-transform duration-700"></div>
              <div className="relative flex items-start space-x-4">
                <div className="text-[#ffa0c5]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="lucide lucide-skull w-6 h-6"
                  >
                    <path d="m12.5 17-.5-1-.5 1h1z"></path>
                    <path d="M15 22a1 1 0 0 0 1-1v-1a2 2 0 0 0 1.56-3.25 8 8 0 1 0-11.12 0A2 2 0 0 0 8 20v1a1 1 0 0 0 1 1z"></path>
                    <circle cx="15" cy="12" r="1"></circle>
                    <circle cx="9" cy="12" r="1"></circle>
                  </svg>
                </div>
                <div className="flex-1">
                  <div className="flex items-center justify-between">
                    <h3 className="text-[#ffa0c5] font-mono text-lg mb-1">
                      HACKED
                    </h3>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="lucide lucide-chevron-right w-5 h-5 text-[#ffa0c5] transform group-hover:translate-x-1  transition-transform"
                    >
                      <path d="m9 18 6-6-6-6"></path>
                    </svg>
                  </div>
                  <p className="text-[#ffa0c5]/70 text-sm font-mono">
                    View compromised systems and extracted data archives
                  </p>
                </div>
              </div>
              <div className="absolute inset-0 bg-[#ffa0c5]/20 transform translate-y-full  group-hover:translate-y-0 transition-transform duration-300"></div>
            </button>
          </div>
          <div className="absolute -left-1 top-1/2 w-1 h-8 bg-[#ffa0c5]/50 transform -translate-y-1/2"></div>
          <div className="absolute -right-1 top-1/2 w-1 h-8 bg-[#ffa0c5]/50 transform -translate-y-1/2"></div>
        </div>
      </div>
    </>
  );
}

export default Hamburger;
