import Hamburger from "../../components/hamburger";
import TerminalExe from "../../components/terminal";

function Terminal() {
  return (
    <div className="relative min-h-screen bg-black font-mono overflow-hidden text-white">
      <Hamburger />
      <div className="fixed inset-0 pointer-events-none opacity-10 bg-terminal" />
      <TerminalExe />
    </div>
  );
}

export default Terminal;
