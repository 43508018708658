"use client";

export default function FooterComponent() {
  return (
    <footer className="relative z-10 py-8 text-center border-t border-[#ffa0c5]/20">
      <p className="text-sm text-gray-400 font-mono">
        Built with{" "}
        <span className="inline-block text-[#ffa0c5] animate-pulse transform hover:scale-110 transition-transform duration-300">
          ❤
        </span>
      </p>
    </footer>
  );
}
