import { ReactNode } from "react";

type IProps = {
  children: ReactNode;
  className?: string;
};

function MainLayout({ children, className = "" }: IProps) {
  const handleMouseMove = () => {
    document.addEventListener("mousemove", (e) => {
      const cursor: any = document.querySelector(".blob");
      if (!cursor) return;
      cursor.style.transform = `translate3d(calc(${e.clientX}px - 50%), calc(${e.clientY}px - 50%), 0)`;
    });
  };

  return (
    <main
      onMouseMove={handleMouseMove}
      className={`${className}`}
      style={{
        fontFamily:
          "ui-monospace,SFMono-Regular,Menlo,Monaco,Consolas,Liberation Mono,Courier New,monospace",
      }}
    >
      {children}
    </main>
  );
}

export default MainLayout;
