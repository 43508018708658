import { Link } from "react-router-dom";

export default function BannerComponent() {
  return (
    <section className="relative min-h-screen flex flex-col items-center justify-center px-4 sm:px-6">
      <div className="text-center relative animate-fade-in w-full max-w-[800px] mx-auto">
        <div className="ascii-bloom mb-8 sm:mb-12 px-2 sm:px-0">
          <pre className="text-[0.6rem] sm:text-sm md:text-lg lg:text-xl font-mono text-[#ffa0c5] whitespace-pre leading-[0.7rem] sm:leading-none transform scale-75 sm:scale-100">
            <p>███╗ ███╗██╗ █████ ██████╗ ██╗</p>
            <p>████╗ ████║██║██╔═══██╗██║ ██║ ██║</p>
            <p>██╔████╔██║██║██║ ██║█████╔╝ ██║</p>
            <p>██║╚██╔╝██║██║██║ ██║██╔═██╗ ██║</p>
            <p>██║ ╚═╝ ██║██║ █████╔╝██║ ██╗ ██║</p>
            <p>╚═╝ ╚═╝╚═╝ ╚═══╝ ╚═╝ ╚═╝ ╚═╝</p>
          </pre>
        </div>
        <h2 className="text-lg sm:text-xl md:text-2xl mb-8 sm:mb-12 cyberpunk-scan relative px-4">
          <span className="text-[#ffa0c5]/80 font-orbitron tracking-wider sm:tracking-widest glitch-text">
            CYBERSECURITY ECOSYSTEM FOR SOLANA
          </span>
        </h2>
        <div className="flex flex-col items-center">
          <div className="flex flex-col sm:flex-row gap-4 sm:gap-6 justify-center w-full sm:w-auto mb-16 sm:mb-24 px-4">
            <Link
              to="/terminal"
              className="cyberpunk-button primary text-base sm:text-lg w-full sm:w-auto py-4 sm:py-2"
            >
              Launch Terminal
            </Link>
            {/* <a
              href="#"
              className="cyberpunk-button secondary text-base sm:text-lg w-full sm:w-auto py-4 sm:py-2"
            >
              Learn More
            </a> */}
            <a
              href="/"
              target="_blank"
              rel="noopener noreferrer"
              className="cyberpunk-button text-base sm:text-lg w-full sm:w-auto py-4 sm:py-2 border border-white text-white"
            >
              Learn More
            </a>
          </div>
          <div className="text-center w-full">
            <div className="text-[#ffa0c5] text-sm scroll-text tracking-widest">
              SCROLL TO EXPLORE
            </div>
            <svg
              className="w-6 h-6 mx-auto mt-2 animate-bounce"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 4L12 20M12 20L18 14M12 20L6 14"
                stroke="#ffa0c5"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
    </section>
  );
}
